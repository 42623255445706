<template>
          <v-card
            color="grey lighten-4"
            min-width="450px"
            flat
            elevation=0
          >
            <v-toolbar
              :color="selectedEvent.color"
              :dark="dark"
              :elevation="1"
            >
              <v-btn icon 
                :color="dark ? 'black' : 'white'"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"
                :style="{ color: dark ? 'black' : 'white' }"
              ></v-toolbar-title>
              <v-spacer/>
              <v-btn fab small 
                v-if="free !== null && allowReservations"
                color="white"
              :dark="dark"
                elevation="0"
              >
              <span 
                :style="{color: freeColor}"
              >
                {{free}}
              </span>
              </v-btn>
              <v-btn text @click="onCloseEvent"
                :color="dark ? 'black' : 'white'"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <!--v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn--->
            </v-toolbar>
            <v-card-text>
              <v-progress-linear
                v-if="loading"
                indeterminate
                :color="selectedEvent.color"
              />
              <v-layout wrap v-if="!loading && free !== null && allowReservations">
                <v-flex xs4>
                  <b>{{$t('booking.calendar.available', locale)}}: </b> {{free}}
                </v-flex>
                <v-flex xs4>
                  <b>{{$t('booking.calendar.reserved', locale)}}: </b> {{book}}
                </v-flex>
                <v-flex xs4>
                  <b>{{$t('booking.calendar.canceled', locale)}}: </b> {{cancel}}
                </v-flex>
              </v-layout>
              <v-layout wrap v-if="selectedEvent.source === 'contentmanager'">
                <v-flex xs6 style="padding-right: 5px">
                  <v-btn block 
                    color="primary"
                    @click="handleEditEvent"
                    style="margin-top:10px;"
                  >
                  <v-icon>mdi-pencil</v-icon> {{ $t('booking.calendar.editEvent', locale) }}
                  </v-btn>
                  <edit-event-form
                    :eventID="editedEventID"
                    :onClose="handleCloseEditEvent"
                    :locale="locale"
                  />
                </v-flex>
                <v-flex xs6 style="padding-left: 5px">
                  <v-btn block 
                    color="primary"
                    dark
                    @click="handleHideEvent"
                    style="margin-top:10px;"
                  >
                    <v-icon>mdi-eye</v-icon> {{ $t(`booking.calendar.${hide ? 'showEvent' : 'hideEvent'}`, locale) }}
                  </v-btn>
                </v-flex>

                <v-flex xs6 style="padding-right: 5px">
                  <v-btn block 
                    color="primary"
                    dark
                    @click="handleMoveEvent"
                    style="margin-top:10px;"
                  >
                    {{ $t('booking.calendar.moveEvent', locale) }}
                  </v-btn>
                </v-flex>
                <v-flex xs6 style="padding-left: 5px">
                  <v-btn block 
                    color="red"
                    dark
                    @click="handleDeleteEvent"
                    style="margin-top:10px;"
                  >
                  <v-icon>mdi-delete</v-icon> {{ $t('booking.calendar.deleteEvent', locale) }}
                  </v-btn>
                </v-flex>
                <v-flex xs12
                  v-if="free !== null && showActivityMenu"
                >
                  <v-btn block 
                    @click="handleGoToActivityForm"
                    style="margin-top:10px;"
                  >
                    {{ $t('booking.activities.editActivity', locale) }}
                  </v-btn>
                </v-flex>
                <v-flex xs12>
                  <v-btn block 
                    v-if="free !== null && showReservationMenu"
                    @click="handleGoToReservationList"
                    style="margin-top: 10px"
                  >
                    {{ $t('booking.calendar.goToReservationList', locale) }}
                  </v-btn>
                </v-flex>
              </v-layout>
              <!--
              <v-img v-if="selectedEvent.image" :src="selectedEvent.image" style="width: 200px; height:auto;" />
              -->
              <small style="opacity: 0.8;">{{scheduleName}}</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                color="primary"
                dark
                @click="onCloseEvent"
              >
                {{ $t('common.close', locale) }}
              </v-btn>
            </v-card-actions>
          </v-card>
</template>
<script>
import tinycolor from 'tinycolor2'
import api from '@/services/api'
import EditEventForm from './EditEventForm'
export default {
  components: {
    EditEventForm,
  },
  props: {
    showReservationMenu: {
      type: Boolean,
      default: false,
    },
    showActivityMenu: {
      type: Boolean,
      default: false,
    },
    selectedEvent: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onCloseEvent: {
      type: Function,
      required: true,
    },
    onDeleteEvent: {
      type: Function,
      required: true,
    },
    onMoveEvent: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    scheduleName: '',
    editedEventID: null,
    hide: false,
    book: 0,
    free: 0,
    cancel: 0,
    allowReservations: false,
  }),
  computed: {
    freeColor () {
      return tinycolor(this.selectedEvent.color).isLight() ? '#000000' : this.selectedEvent.color
      //return utils.getOppositeColor(this.selectedEvent.color)
    },
    dark () {
     return tinycolor(this.selectedEvent.color).isLight() 
    }
  },
  watch: {
    selectedEvent () {
      this.handleGetAvailability()
    },
  },
  mounted () {
    this.handleGetAvailability()
  },
  methods: {
    handleCloseEditEvent () {
      this.editedEventID = null
      this.handleGetAvailability()
    },
    handleGetScheduleName (v) {
      api.getItem('booking', `v1/private/schedules/`, v, false)
        .then(response => {
          this.scheduleName = response ? response.Alias : ''
        })
    },
    handleGetAvailability () {
      this.loading = true
      if (!this.selectedEvent.id) return

      let id = this.selectedEvent.id
      api.getItem('booking', `v1/private/events/`, id, false)
        .then(response => {
          if(response) {
            this.allowReservations = response.AllowReservations
            if (response.ScheduleID) {
              this.handleGetScheduleName(response.ScheduleID)
            } else {
              this.scheduleName = ''
            }
            this.hide = response.Hide ? true : false
            this.book = response.Book ? response.Book : 0
            this.cancel = response.Cancel ? response.Cancel : 0
          } 
          id += this.selectedEvent.isActtiv ? '/availability-acttiv' : '/availability'
          api.getItem('booking', `v1/public/events/`, id, false)
            .then(response => {
              if (response) this.free = response.availability
              this.loading = false
            })
        })
/*
      id += this.selectedEvent.isActtiv ? '/availability-acttiv' : '/availability'
      api.getItem('booking', `v1/public/events/`, id, false)
        .then(response => {
          this.selectedEvent.free = response.availability
          this.loading = false
        })
*/
    },
    handleGoToReservationList () {
      const date = new Date(this.selectedEvent.start).toISOString().split('T').shift()
      this.$router.push({ path: `/booking/reservations?date=${date}` })
    },
    handleGoToActivityForm() {
      this.$router.push({ path: `/booking/activities/${this.selectedEvent.activityID}` })
    },
    handleHideEvent () {
      api.updateItem ('booking', `v1/private/events/`, this.selectedEvent.id.concat('/hide'), {Hide: !this.hide})
        .then(response => {
          this.hide = !this.hide
          if(response) this.onCloseEvent(true)
        })
        .catch (() => {
          alert( 'Error' )
        })
    },
    handleEditEvent () {
      this.editedEventID = this.selectedEvent.id
    },
    handleDeleteEvent () {
      this.onDeleteEvent(this.selectedEvent.id)
    },
    handleMoveEvent () {
      this.onMoveEvent(this.selectedEvent.id)
    },
  },
}
</script>

